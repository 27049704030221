@media (max-width: 767px) {
	.hidden-mobile {
    display: none !important;
	}
}

@media (min-width: 768px) {
	.hidden-desktop {
    display: none !important;
	}
}

#components-layout-demo-fixed-sider .logo {
  padding: 20px 28px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ant-layout-sider-trigger {
  display: none;
}

.logo {
  color: #333;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  position: relative;
  b {
    color: #9f9f9f;
    font-size: .6rem;
    font-weight:300;
    text-transform: uppercase;
    letter-spacing: normal;
    position:absolute;
bottom:10px;
    right:36px;
  }
}

.site-layout .ant-layout-header.site-layout-background {
  background: #fff;

}

.site-layout .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.site-layout .trigger:hover {
  color: #1890ff;
}

.companySelector {
  display: flex;
  align-items: center;
  gap: .5rem;
  margin-left: auto;
  justify-content: flex-end;
  padding-right: 30px;

  img {
    border-radius: 50%;
    border: 2px solid #333;
  }

  span {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    padding-left: 10px;
  }
}

#components-landing-page-layout {
  background-color: #fff;
  color:rgb(0, 0, 0);
  font-family: 'Montserrat', sans-serif;
}

// overriding Ant Design modal styles by having styles in the
// global file.
.ant-modal-content {
  border-radius: 5px 5px 5px 5px;
  border: 1px solid rgb(114, 114, 114);
}

.ant-modal-header {
  border-radius: 5px 5px 0 0;
}
